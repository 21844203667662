'use client'
import { useSplitText } from '@/hooks/useSplitText'
import { LazyMotion, domAnimation, m, useInView } from 'framer-motion'
import React, { useRef } from 'react'
const title = {
  visible: {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.045,
    },
  },
}
const sentence = {
  visible: {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.03,
    },
  },
}
const clip = {
  hidden: {
    opacity: 0,
    clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
  },
  visible: {
    opacity: 1,
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
    color: ['#000000', '#242A32', '#6E95FF', '#000000'],
    transition: {
      duration: 0.8,
      ease: [0.5, 1, 0.35, 1],
    },
  },
}
const blueClip = {
  hidden: {
    opacity: 0,
    clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
  },
  visible: {
    opacity: 1,
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
    color: ['#000000', '#953F75', '#6E95FF'],
    transition: {
      duration: 0.8,
      ease: [0.5, 1, 0.35, 1],
    },
  },
}
const blackMove = {
  hidden: {
    opacity: 0,
    x: 30,
  },
  visible: {
    opacity: 1,
    x: 0,
    color: ['#000000', '#953F75', '#6E95FF', '#000000'],
    transition: {
      duration: 1.1,
      ease: [0.3, 1, 0.7, 1],
    },
  },
}
const whiteMove = {
  hidden: {
    opacity: 0,
    x: 30,
  },
  visible: {
    opacity: 1,
    x: 0,
    color: ['#ffffff', '#953F75', '#6E95FF', '#ffffff'],
    transition: {
      duration: 1,
      ease: [0.3, 1, 0.7, 1],
    },
  },
}
export default React.memo(function AnimatedText({
  txt_name,
  color,
}: {
  txt_name: string
  color: 'clip' | 'blueClip' | 'blackMove' | 'whiteMove'
}) {
  const parts = useSplitText(txt_name)
  const textRef = useRef(null)
  const isInView = useInView(textRef, { once: true })
  const latinRegex = /^[a-zA-Z\s]+$/
  const japaneseRegex =
    /[\u3040-\u309F\u30A0-\u30FF\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF\uD840-\uD869\uD87E-\uD87F\uD880-\uD8BF\uD8C0-\uD8FF\uD900-\uD93F\uD940-\uD97F\uD980-\uD9BF\uD9C0-\uD9FF\u{20000}-\u{2EBEF}\u{2F800}-\u{2FA1F}]/u
  return (
    <LazyMotion features={domAnimation} strict>
      <m.span
        className="relative"
        variants={color == 'blackMove' || 'whiteMove' ? title : sentence}
        initial="hidden"
        animate={isInView ? 'visible' : 'hidden'}
        exit="hidden"
        ref={textRef}
      >
        {txt_name &&
          parts.map((part, index) => {
            if (part.type == 'br') {
              return part.className != undefined ? (
                <br className={part.className} key={`br-${index}`} />
              ) : (
                <br key={`br-${index}`} />
              )
            } else {
              return (
                <React.Fragment key={`prElm_${index}${Math.random() * index}`}>
                  {part.value.split('').map((char, i) => (
                    <span
                      key={`keyFirst2_${char}_${i}_${Math.random() * (i + 1)}`}
                      className={`overflow-hidden ${latinRegex.test(char) ? 'font-avenirNextBold' : ''}${japaneseRegex.test(char) ? 'font-notosans_jp font-bold' : ''}`}
                    >
                      <m.span
                        variants={
                          color == 'clip'
                            ? clip
                            : color == 'blueClip'
                              ? blueClip
                              : color == 'blackMove'
                                ? blackMove
                                : whiteMove
                        }
                        style={{ display: 'inline-block' }}
                      >
                        {char === ' ' ? '\u00A0' : char}
                      </m.span>
                    </span>
                  ))}
                </React.Fragment>
              )
            }
          })}
      </m.span>
    </LazyMotion>
  )
})
