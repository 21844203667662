type SplitPart = {
  type: 'text' | 'br'
  value: string
  className?: string
}

export const useSplitText = (inputString: string): SplitPart[] => {
  const parts: SplitPart[] = []

  // Regular expression to match <br> tags with class names
  const regex = /<br(?:\s+className=(['"])(.*?)\1\s*)?\/?>/g
  let lastIndex = 0
  let match

  while ((match = regex.exec(inputString)) !== null) {
    // Push text before the <br> tag
    const textBeforeBr = inputString.substring(lastIndex, match.index)
    if (textBeforeBr) {
      parts.push({ type: 'text', value: textBeforeBr })
    }

    // Push <br> tag
    const className = match[2] // Capture group for class name
    parts.push({ type: 'br', value: match[0], className })

    lastIndex = regex.lastIndex
  }
  
  if (inputString === undefined || inputString === '') {
    return parts
  }

  // Push remaining text after the last <br> tag
  const remainingText = inputString.substring(lastIndex)
  if (remainingText) {
    parts.push({ type: 'text', value: remainingText })
  }

  return parts
}
